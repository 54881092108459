.GestionDesigners_modal {
    .hora-inicio-label {
        margin-bottom: 0.55rem;
    }
}

.lista-horario {
    &__titulo {
        border-bottom: 1px solid rgba(16, 171, 205, 0.5);
    }
    tr {
        th {
            padding: 0.5rem;
            text-align: center;
        }
        td {
            text-align: center;
            &:first-child {
                width: 12.3rem;
            }
        }
    }
}

.gestion-horario-calendar {
    .react-datepicker-wrapper {
        width: 100%;
    }
}
