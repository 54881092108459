.NewsItem {
    position: relative;
    height: 230px;
    .anchor-wrap-image {
        display: block;
        height: 100%;
        &:focus-visible {
            text-decoration: none;
            border: none;
            outline: none;
        }
        &:hover {
            img {
                transform: scale(1.04);
            }
        }
    }
    img {
        object-fit: scale-down;
    }
}
.slick-slider {
    .slick-dots {
        bottom: -15px;
    }
}
.cardButton {
    position: absolute;
    z-index: 3;
}
.card-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(197, 23, 23, 0.5);
    z-index: 1;
    visibility: visible;
}
