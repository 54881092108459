.accordion-card {
    overflow: hidden;
    position: relative;
    &_arrow {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        margin: 0.5rem !important;
        transition: 0.3s;
        &:hover {
            color: #1692ad !important;
        }
    }
    &_body {
        transition: 0.5s ease !important;
        overflow: hidden;
        position: relative;
        height: 100%;
        max-height: 0 !important;
        &_expanded {
            max-height: 292px !important;
            transition: 0.5s ease !important;
        }
    }
}
