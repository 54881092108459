//
// accordions.scss
//

.custom-accordion {
    .card {
        box-shadow: none;
    }

    .card-header {
        background-color: #{map-get($grays, '100')};
    }

    .card-body {
        border: #{($border-width) solid map-get($grays, '100')};
    }

    .accordion-arrow {
        font-size: 1.2rem;
        position: absolute;
        right: 0;
    }
    a {
        &.collapsed {
            i.accordion-arrow {
                &:before {
                    content: '\F142';
                }
            }
        }
    }
}

.custom-accordion-title {
    color: $custom-accordion-title-color;
    position: relative;

    .accordion-button:not(.collapsed) {
        background-color: transparent;
        color: #12191d;
    }
    .accordion-button {
        &:hover {
            background-color: hwb(214 19% 73% / 0.05);
        }
        &:focus {
            box-shadow: none;
        }
    }
}

.accordion {
    > .card {
        > .card-header {
            border-radius: 0;
            margin-bottom: -1px;
        }
    }
}

// Dark mode
body[data-layout-color='dark'] {
    .custom-accordion {
        .card-header {
            background-color: #{map-get($dark-grays, '100')};
        }
        .card-body {
            border: #{($border-width) solid map-get($dark-grays, '100')};
        }
    }
    .custom-accordion-title {
        &:hover {
            color: #{lighten(map-get($dark-grays, '400'), 7.5%)};
        }
    }
}
