$variable-prefix: 'ct-';
$white: #fff !default;
$gray-100: #f5f5f5;
$gray-200: #e7e7e7;
$gray-300: #cfcfcf !default;
$gray-400: #cac4c4 !default;
$gray-500: #6a6a6a !default;
$gray-600: #6c757d;
$gray-700: #404957;
$gray-800: #313a46 !default;
$gray-900: #12191d;
$black: #000 !default;
$grays: (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
);

$weikbluelight: #f1f9fb;
$weikblue: #10abcd;
$weikorange: #dd9933;
$weikpurple: #993399;

$blue: #2c8ef8;
$indigo: #727cf5;
$purple: $weikpurple;
$pink: #ff679b;
$red: #fa5c7c;
$orange: $weikorange;
$yellow: #ffbc00;
$green: #0acf97;
$teal: #02a8b5;
$cyan: $weikblue;
$colors: (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
    'weikblue': $weikblue,
    'weikorange': $weikorange,
    'weikpurple': $weikpurple,
);
$primary: $weikblue;
$secondary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-900;
$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'purple': $purple
);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$min-contrast-ratio: 1.8;
$color-contrast-dark: $gray-900;
$color-contrast-light: $white;
$blue-100: tint-color($blue, 80%);
$blue-200: tint-color($blue, 60%);
$blue-300: tint-color($blue, 40%);
$blue-400: tint-color($blue, 20%);
$blue-500: $blue;
$blue-600: shade-color($blue, 20%);
$blue-700: shade-color($blue, 40%);
$blue-800: shade-color($blue, 60%);
$blue-900: shade-color($blue, 80%);

$indigo-100: tint-color($indigo, 80%);
$indigo-200: tint-color($indigo, 60%);
$indigo-300: tint-color($indigo, 40%);
$indigo-400: tint-color($indigo, 20%);
$indigo-500: $indigo;
$indigo-600: shade-color($indigo, 20%);
$indigo-700: shade-color($indigo, 40%);
$indigo-800: shade-color($indigo, 60%);
$indigo-900: shade-color($indigo, 80%);

$purple-100: tint-color($purple, 80%);
$purple-200: tint-color($purple, 60%);
$purple-300: tint-color($purple, 40%);
$purple-400: tint-color($purple, 20%);
$purple-500: $purple;
$purple-600: shade-color($purple, 20%);
$purple-700: shade-color($purple, 40%);
$purple-800: shade-color($purple, 60%);
$purple-900: shade-color($purple, 80%);

$pink-100: tint-color($pink, 80%);
$pink-200: tint-color($pink, 60%);
$pink-300: tint-color($pink, 40%);
$pink-400: tint-color($pink, 20%);
$pink-500: $pink;
$pink-600: shade-color($pink, 20%);
$pink-700: shade-color($pink, 40%);
$pink-800: shade-color($pink, 60%);
$pink-900: shade-color($pink, 80%);

$red-100: tint-color($red, 80%);
$red-200: tint-color($red, 60%);
$red-300: tint-color($red, 40%);
$red-400: tint-color($red, 20%);
$red-500: $red;
$red-600: shade-color($red, 20%);
$red-700: shade-color($red, 40%);
$red-800: shade-color($red, 60%);
$red-900: shade-color($red, 80%);

$orange-100: tint-color($orange, 80%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 80%);

$yellow-100: tint-color($yellow, 80%);
$yellow-200: tint-color($yellow, 60%);
$yellow-300: tint-color($yellow, 40%);
$yellow-400: tint-color($yellow, 20%);
$yellow-500: $yellow;
$yellow-600: shade-color($yellow, 20%);
$yellow-700: shade-color($yellow, 40%);
$yellow-800: shade-color($yellow, 60%);
$yellow-900: shade-color($yellow, 80%);

$green-100: tint-color($green, 80%);
$green-200: tint-color($green, 60%);
$green-300: tint-color($green, 40%);
$green-400: tint-color($green, 20%);
$green-500: $green;
$green-600: shade-color($green, 20%);
$green-700: shade-color($green, 40%);
$green-800: shade-color($green, 60%);
$green-900: shade-color($green, 80%);

$teal-100: tint-color($teal, 80%);
$teal-200: tint-color($teal, 60%);
$teal-300: tint-color($teal, 40%);
$teal-400: tint-color($teal, 20%);
$teal-500: $teal;
$teal-600: shade-color($teal, 20%);
$teal-700: shade-color($teal, 40%);
$teal-800: shade-color($teal, 60%);
$teal-900: shade-color($teal, 80%);

$cyan-100: tint-color($cyan, 80%);
$cyan-200: tint-color($cyan, 60%);
$cyan-300: tint-color($cyan, 40%);
$cyan-400: tint-color($cyan, 20%);
$cyan-500: $cyan;
$cyan-600: shade-color($cyan, 20%);
$cyan-700: shade-color($cyan, 40%);
$cyan-800: shade-color($cyan, 60%);
$cyan-900: shade-color($cyan, 80%);

$blues: (
    'blue-100': $blue-100,
    'blue-200': $blue-200,
    'blue-300': $blue-300,
    'blue-400': $blue-400,
    'blue-500': $blue-500,
    'blue-600': $blue-600,
    'blue-700': $blue-700,
    'blue-800': $blue-800,
    'blue-900': $blue-900,
);

$indigos: (
    'indigo-100': $indigo-100,
    'indigo-200': $indigo-200,
    'indigo-300': $indigo-300,
    'indigo-400': $indigo-400,
    'indigo-500': $indigo-500,
    'indigo-600': $indigo-600,
    'indigo-700': $indigo-700,
    'indigo-800': $indigo-800,
    'indigo-900': $indigo-900,
);

$purples: (
    'purple-100': $purple-200,
    'purple-200': $purple-100,
    'purple-300': $purple-300,
    'purple-400': $purple-400,
    'purple-500': $purple-500,
    'purple-600': $purple-600,
    'purple-700': $purple-700,
    'purple-800': $purple-800,
    'purple-900': $purple-900,
);

$pinks: (
    'pink-100': $pink-100,
    'pink-200': $pink-200,
    'pink-300': $pink-300,
    'pink-400': $pink-400,
    'pink-500': $pink-500,
    'pink-600': $pink-600,
    'pink-700': $pink-700,
    'pink-800': $pink-800,
    'pink-900': $pink-900,
);

$reds: (
    'red-100': $red-100,
    'red-200': $red-200,
    'red-300': $red-300,
    'red-400': $red-400,
    'red-500': $red-500,
    'red-600': $red-600,
    'red-700': $red-700,
    'red-800': $red-800,
    'red-900': $red-900,
);

$oranges: (
    'orange-100': $orange-100,
    'orange-200': $orange-200,
    'orange-300': $orange-300,
    'orange-400': $orange-400,
    'orange-500': $orange-500,
    'orange-600': $orange-600,
    'orange-700': $orange-700,
    'orange-800': $orange-800,
    'orange-900': $orange-900,
);

$yellows: (
    'yellow-100': $yellow-100,
    'yellow-200': $yellow-200,
    'yellow-300': $yellow-300,
    'yellow-400': $yellow-400,
    'yellow-500': $yellow-500,
    'yellow-600': $yellow-600,
    'yellow-700': $yellow-700,
    'yellow-800': $yellow-800,
    'yellow-900': $yellow-900,
);

$greens: (
    'green-100': $green-100,
    'green-200': $green-200,
    'green-300': $green-300,
    'green-400': $green-400,
    'green-500': $green-500,
    'green-600': $green-600,
    'green-700': $green-700,
    'green-800': $green-800,
    'green-900': $green-900,
);

$teals: (
    'teal-100': $teal-100,
    'teal-200': $teal-200,
    'teal-300': $teal-300,
    'teal-400': $teal-400,
    'teal-500': $teal-500,
    'teal-600': $teal-600,
    'teal-700': $teal-700,
    'teal-800': $teal-800,
    'teal-900': $teal-900,
);

$cyans: (
    'cyan-100': $cyan-100,
    'cyan-200': $cyan-200,
    'cyan-300': $cyan-300,
    'cyan-400': $cyan-400,
    'cyan-500': $cyan-500,
    'cyan-600': $cyan-600,
    'cyan-700': $cyan-700,
    'cyan-800': $cyan-800,
    'cyan-900': $cyan-900,
);
$escaped-characters: (('<', '%3c'), ('>', '%3e'), ('#', '%23'), ('(', '%28'), (')', '%29'));

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-container-classes: true;
$enable-cssgrid: false;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: true;
$enable-deprecation-messages: true;
$enable-important-utilities: true;
$gradient: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0));
$spacer: 1.5rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);
$position-values: (
    0: 0,
    50: 50%,
    100: 100%,
);

$body-bg: #fafbfe;
$body-color: $gray-700;
$body-text-align: null;

$utilities-colors: $theme-colors-rgb;
$utilities-text: map-merge(
    $utilities-colors,
    (
        'black': to-rgb($black),
        'white': to-rgb($white),
        'body': to-rgb($body-color),
    )
);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, '$key', 'text');
$utilities-bg: map-merge(
    $utilities-colors,
    (
        'black': to-rgb($black),
        'white': to-rgb($white),
        'body': to-rgb($body-bg),
    )
);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, '$key', 'bg');

$link-color: var(--#{$variable-prefix}link-color);
$link-decoration: none;
$link-shade-percentage: 15%;
$link-hover-color: var(--#{$variable-prefix}link-hover-color);
$link-hover-decoration: null;

$stretched-link-pseudo-element: after;
$stretched-link-z-index: 1;

$paragraph-margin-bottom: 1rem;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
);

@include _assert-ascending($container-max-widths, '$container-max-widths');

$grid-columns: 12;
$grid-gutter-width: 24px;
$grid-row-columns: 6;

$gutters: $spacers;

$container-padding-x: $grid-gutter-width * 0.5;

$border-width: 1px;
$border-widths: (
    0: 0,
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
);

$border-color: var(--#{$variable-prefix}border-color);
$border-radius: 0.25rem;
$border-radius-sm: 0.2rem;
$border-radius-lg: 0.3rem;
$border-radius-pill: 50rem;
$box-shadow: var(--#{$variable-prefix}box-shadow);
$box-shadow-sm: var(--#{$variable-prefix}box-shadow-sm);
$box-shadow-lg: var(--#{$variable-prefix}box-shadow-lg);
$box-shadow-inset: var(--#{$variable-prefix}box-shadow-inset);

$component-active-color: var(--#{$variable-prefix}component-active-color);
$component-active-bg: var(--#{$variable-prefix}component-active-bg);

$caret-width: 0.25em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;
$transition-collapse-width: width 0.35s ease;
$aspect-ratios: (
    '1x1': 100%,
    '4x3': calc(3 / 4 * 100%),
    '16x9': calc(9 / 16 * 100%),
    '21x9': calc(9 / 21 * 100%),
);

$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-monospace: 'Roboto', 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
$font-family-code: var(--#{$variable-prefix}font-monospace);

$font-size-root: null;
$font-size-base: 0.9rem;
$font-size-sm: $font-size-base - 0.025;
$font-size-lg: $font-size-base * 1.39;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: 2.25rem;
$h2-font-size: 1.875rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.125rem;
$h5-font-size: 0.9375rem;
$h6-font-size: 0.75rem;

$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
);

$headings-margin-bottom: $spacer * 0.5;
$headings-font-family: null;
$headings-font-style: null;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: null;

$display-font-sizes: (
    1: 5rem,
    2: 4.5rem,
    3: 4rem,
    4: 3.5rem,
    5: 3rem,
    6: 2.5rem,
);

$display-font-weight: 300;
$display-line-height: $headings-line-height;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 0.75rem;

$sub-sup-font-size: 0.75em;

$text-muted: var(--#{$variable-prefix}text-muted);

$initialism-font-size: $small-font-size;

$blockquote-margin-y: $spacer;
$blockquote-font-size: $font-size-base * 1.25;
$blockquote-footer-color: var(--#{$variable-prefix}blockquote-footer-color);
$blockquote-footer-font-size: $small-font-size;

$hr-margin-y: $spacer - 0.5rem;
$hr-color: inherit;
$hr-bg-color: currentColor;
$hr-border-width: 0;
$hr-height: $border-width;
$hr-opacity: 0.25;

$legend-margin-bottom: 0.5rem;
$legend-font-size: 1.5rem;
$legend-font-weight: null;

$mark-padding: 0.2em;

$dt-font-weight: $font-weight-bold;

$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: 6px;

$mark-bg: var(--#{$variable-prefix}mark-bg);

$table-cell-padding-y: 0.95rem;
$table-cell-padding-x: 0.95rem;
$table-cell-padding-y-sm: 0.5rem;
$table-cell-padding-x-sm: 0.5rem;

$table-cell-vertical-align: top;

$table-color: var(--#{$variable-prefix}table-color);
$table-bg: var(--#{$variable-prefix}table-bg);
$table-accent-bg: var(--#{$variable-prefix}table-accent-bg);

$table-th-font-weight: null;

$table-striped-color: var(--#{$variable-prefix}table-striped-color);
$table-striped-bg-factor: 1;
$table-striped-bg: var(--#{$variable-prefix}table-striped-bg);

$table-active-color: var(--#{$variable-prefix}table-active-color);
$table-active-bg-factor: 0.4;
$table-active-bg: var(--#{$variable-prefix}table-active-bg);

$table-hover-color: var(--#{$variable-prefix}table-hover-color);
$table-hover-bg-factor: 0.075;
$table-hover-bg: var(--#{$variable-prefix}table-hover-bg);

$table-border-factor: 0.1;
$table-border-width: $border-width;
$table-border-color: var(--#{$variable-prefix}table-border-color);

$table-striped-order: odd;

$table-group-separator-color: var(--#{$variable-prefix}table-group-separator-color);

$table-caption-color: var(--#{$variable-prefix}table-caption-color);

$table-bg-scale: -80%;

$table-variants: (
    'primary': shift-color($primary, $table-bg-scale),
    'secondary': shift-color($secondary, $table-bg-scale),
    'success': shift-color($success, $table-bg-scale),
    'info': shift-color($info, $table-bg-scale),
    'warning': shift-color($warning, $table-bg-scale),
    'danger': shift-color($danger, $table-bg-scale),
    'light': $gray-200,
    'dark': $gray-900,
);

$input-btn-padding-y: 0.45rem;
$input-btn-padding-x: 0.9rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0.2rem;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-color: var(--#{$variable-prefix}input-btn-focus-color);
$input-btn-focus-blur: 0;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.28rem;
$input-btn-padding-x-sm: 0.8rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;

$input-btn-border-width: $border-width;

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: 0px 2px 6px 0px;
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: var(--#{$variable-prefix}btn-active-box-shadow);

$btn-link-color: var(--#{$variable-prefix}btn-link-color);
$btn-link-hover-color: var(--#{$variable-prefix}btn-link-hover-color);
$btn-link-disabled-color: var(--#{$variable-prefix}btn-link-disabled-color);

$btn-border-radius: 0.15rem;
$btn-border-radius-sm: 0.15rem;
$btn-border-radius-lg: 0.15rem;

$btn-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

$btn-hover-bg-shade-amount: 15%;
$btn-hover-bg-tint-amount: 15%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 10%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;

$form-text-margin-top: 0.25rem;
$form-text-font-size: $small-font-size;
$form-text-font-style: null;
$form-text-font-weight: null;
$form-text-color: var(--#{$variable-prefix}form-text-color);

$form-label-margin-bottom: 0.5rem;
$form-label-font-size: null;
$form-label-font-style: null;
$form-label-font-weight: null;
$form-label-color: null;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;

$input-bg: var(--#{$variable-prefix}input-bg);
$input-disabled-bg: var(--#{$variable-prefix}input-disabled-bg);
$input-disabled-border-color: null;

$input-color: var(--#{$variable-prefix}input-color);
$input-border-color: var(--#{$variable-prefix}input-border-color);
$input-border-width: 1px;
$input-box-shadow: var(--#{$variable-prefix}input-box-shadow);

$input-border-radius: 0.25rem;
$input-border-radius-sm: $border-radius-sm;
$input-border-radius-lg: $border-radius-lg;

$input-focus-bg: var(--#{$variable-prefix}input-focus-bg);
$input-focus-border-color: var(--#{$variable-prefix}input-focus-border-color);
$input-focus-color: var(--#{$variable-prefix}input-focus-color);
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: none;

$input-placeholder-color: var(--#{$variable-prefix}input-placeholder-color);
$input-plaintext-color: var(--#{$variable-prefix}input-plaintext-color);

$input-height-border: $input-border-width * 2;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y);
$input-height-inner-quarter: add($input-line-height * 0.25em, $input-padding-y * 0.5);

$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

$form-color-width: 3rem;

$form-check-input-width: 1.112em;
$form-check-min-height: $font-size-base * $line-height-base;
$form-check-padding-start: $form-check-input-width + 0.5em;
$form-check-margin-bottom: 0;
$form-check-label-color: null;
$form-check-label-cursor: null;
$form-check-transition:
    background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

$form-check-input-active-filter: brightness(90%);

$form-check-input-bg: var(--#{$variable-prefix}form-check-input-bg);
$form-check-input-border: var(--#{$variable-prefix}form-check-input-border);
$form-check-input-border-radius: 0.25em;
$form-check-radio-border-radius: 50%;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: none;

$form-check-input-checked-color: var(--#{$variable-prefix}form-check-input-checked-color);
$form-check-input-checked-bg-color: var(--#{$variable-prefix}form-check-input-checked-bg-color);
$form-check-input-checked-border-color: var(--#{$variable-prefix}form-check-input-checked-border-color);
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='%23fff'/></svg>");

$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity: 0.5;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity;

$form-check-inline-margin-end: 1rem;

$form-switch-color: var(--#{$variable-prefix}form-switch-color);
$form-switch-width: 2em;
$form-switch-padding-start: $form-switch-width + 0.5em;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#8b96a0' opacity='0.5'/></svg>");
$form-switch-border-radius: $form-switch-width;
$form-switch-transition: background-position 0.15s ease-in-out;

$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#8b96a0' opacity='0.5'/></svg>");

$form-switch-checked-color: $component-active-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23fff'/></svg>");
$form-switch-checked-bg-position: right center;

$input-group-addon-padding-y: $input-padding-y;
$input-group-addon-padding-x: $input-padding-x;
$input-group-addon-font-weight: $input-font-weight;
$input-group-addon-color: var(--#{$variable-prefix}input-group-addon-color);
$input-group-addon-bg: var(--#{$variable-prefix}input-group-addon-bg);
$input-group-addon-border-color: var(--#{$variable-prefix}input-group-addon-border-color);

$form-select-padding-y: 0.45rem;
$form-select-padding-x: 0.9rem;
$form-select-font-family: $input-font-family;
$form-select-font-size: $input-font-size;
$form-select-indicator-padding: $form-select-padding-x * 3;
$form-select-font-weight: $input-font-weight;
$form-select-line-height: $input-line-height;
$form-select-color: var(--#{$variable-prefix}form-select-color);
$form-select-bg: var(--#{$variable-prefix}form-select-bg);
$form-select-disabled-color: var(--#{$variable-prefix}form-select-disabled-color);
$form-select-disabled-bg: var(--#{$variable-prefix}form-select-disabled-bg);
$form-select-disabled-border-color: var(--#{$variable-prefix}form-select-disabled-border-color);
$form-select-bg-position: right $form-select-padding-x center;
$form-select-bg-size: 14px 10px;
$form-select-indicator-color: var(--#{$variable-prefix}form-select-indicator-color);
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#8b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding;
$form-select-feedback-icon-position: center right $form-select-indicator-padding;
$form-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half;

$form-select-border-width: $input-border-width;
$form-select-border-color: $input-border-color;
$form-select-border-radius: $border-radius;
$form-select-box-shadow: none;

$form-select-focus-border-color: $input-focus-border-color;
$form-select-focus-width: $input-focus-width;
$form-select-focus-box-shadow: none;

$form-select-padding-y-sm: $input-padding-y-sm;
$form-select-padding-x-sm: $input-padding-x-sm;
$form-select-font-size-sm: $input-font-size-sm;
$form-select-border-radius-sm: $input-border-radius-sm;

$form-select-padding-y-lg: $input-padding-y-lg;
$form-select-padding-x-lg: $input-padding-x-lg;
$form-select-font-size-lg: $input-font-size-lg;
$form-select-border-radius-lg: $input-border-radius-lg;

$form-select-transition: $input-transition;

$form-range-track-width: 100%;
$form-range-track-height: 0.5rem;
$form-range-track-cursor: pointer;
$form-range-track-bg: var(--#{$variable-prefix}form-range-track-bg);
$form-range-track-border-radius: 1rem;
$form-range-track-box-shadow: var(--#{$variable-prefix}form-range-track-box-shadow);

$form-range-thumb-width: 1rem;
$form-range-thumb-height: $form-range-thumb-width;
$form-range-thumb-bg: $component-active-bg;
$form-range-thumb-border: 0;
$form-range-thumb-border-radius: 1rem;
$form-range-thumb-box-shadow: var(--#{$variable-prefix}form-range-thumb-box-shadow);
$form-range-thumb-focus-box-shadow:
    0 0 0 1px $body-bg,
    $input-focus-box-shadow;
$form-range-thumb-focus-box-shadow-width: $input-focus-width;
$form-range-thumb-active-bg: var(--#{$variable-prefix}form-range-thumb-active-bg);
$form-range-thumb-disabled-bg: var(--#{$variable-prefix}form-range-thumb-disabled-bg);
$form-range-thumb-transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

$form-file-button-color: var(--#{$variable-prefix}form-file-button-color);
$form-file-button-bg: var(--#{$variable-prefix}form-file-button-bg);
$form-file-button-hover-bg: var(--#{$variable-prefix}form-file-button-hover-bg);

$form-floating-height: add(3.5rem, $input-height-border);
$form-floating-line-height: 1.25;
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: 0.625rem;
$form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
$form-floating-transition:
    opacity 0.1s ease-in-out,
    transform 0.1s ease-in-out;

$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $form-text-font-size;
$form-feedback-font-style: $form-text-font-style;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

$form-validation-states: (
    'valid': (
        'color': $form-feedback-valid-color,
        'icon': $form-feedback-icon-valid,
    ),
    'invalid': (
        'color': $form-feedback-invalid-color,
        'icon': $form-feedback-icon-invalid,
    ),
);

$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-offcanvas-backdrop: 1040;
$zindex-offcanvas: 1045;
$zindex-modal-backdrop: 1050;
$zindex-modal: 1055;
$zindex-popover: 1070;
$zindex-tooltip: 1080;

$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;
$nav-link-font-size: null;
$nav-link-font-weight: null;
$nav-link-color: null;
$nav-link-hover-color: null;
$nav-link-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
$nav-link-disabled-color: var(--#{$variable-prefix}nav-link-disabled-color);

$nav-tabs-border-color: var(--#{$variable-prefix}nav-tabs-border-color);
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: var(--#{$variable-prefix}nav-tabs-link-hover-border-color);
$nav-tabs-link-active-color: var(--#{$variable-prefix}nav-tabs-link-active-color);
$nav-tabs-link-active-bg: var(--#{$variable-prefix}nav-tabs-link-active-bg);
$nav-tabs-link-active-border-color: var(--#{$variable-prefix}nav-tabs-link-active-border-color);

$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$navbar-padding-y: $spacer * 0.5;
$navbar-padding-x: null;

$navbar-nav-link-padding-x: 0.5rem;

$navbar-brand-font-size: $font-size-lg;

$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) * 0.5;
$navbar-brand-margin-end: 1rem;

$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;
$navbar-toggler-focus-width: $btn-focus-width;
$navbar-toggler-transition: box-shadow 0.15s ease-in-out;

$navbar-dark-color: var(--#{$variable-prefix}navbar-dark-color);
$navbar-dark-hover-color: var(--#{$variable-prefix}navbar-dark-hover-color);
$navbar-dark-active-color: var(--#{$variable-prefix}navbar-dark-active-color);
$navbar-dark-disabled-color: var(--#{$variable-prefix}navbar-dark-disabled-color);
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color: var(--#{$variable-prefix}navbar-dark-toggler-border-color);

$navbar-light-color: var(--#{$variable-prefix}navbar-light-color);
$navbar-light-hover-color: var(--#{$variable-prefix}navbar-light-hover-color);
$navbar-light-active-color: var(--#{$variable-prefix}navbar-light-active-color);
$navbar-light-disabled-color: var(--#{$variable-prefix}navbar-light-disabled-color);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($black, 0.1);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;

$dropdown-min-width: 10rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0.25rem;
$dropdown-spacer: 0.125rem;
$dropdown-font-size: $font-size-base;
$dropdown-color: var(--#{$variable-prefix}dropdown-color);
$dropdown-bg: var(--#{$variable-prefix}dropdown-bg);
$dropdown-border-color: var(--#{$variable-prefix}dropdown-border-color);
$dropdown-border-radius: $border-radius;
$dropdown-border-width: $border-width;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg: var(--#{$variable-prefix}dropdown-divider-bg);
$dropdown-divider-margin-y: $spacer * 0.5;
$dropdown-box-shadow: var(--#{$variable-prefix}dropdown-box-shadow);

$dropdown-link-color: var(--#{$variable-prefix}dropdown-link-color);
$dropdown-link-hover-color: var(--#{$variable-prefix}dropdown-link-hover-color);
$dropdown-link-hover-bg: var(--#{$variable-prefix}dropdown-link-hover-bg);

$dropdown-link-active-color: var(--#{$variable-prefix}dropdown-link-active-color);
$dropdown-link-active-bg: var(--#{$variable-prefix}dropdown-link-active-bg);

$dropdown-link-disabled-color: var(--#{$variable-prefix}dropdown-link-disabled-color);

$dropdown-item-padding-y: 0.375rem;
$dropdown-item-padding-x: $spacer;

$dropdown-header-color: inherit;
$dropdown-header-padding: 0.5rem 1.5rem;

$dropdown-dark-color: var(--#{$variable-prefix}dropdown-dark-color);
$dropdown-dark-bg: var(--#{$variable-prefix}dropdown-dark-bg);
$dropdown-dark-border-color: var(--#{$variable-prefix}dropdown-dark-border-color);
$dropdown-dark-divider-bg: var(--#{$variable-prefix}dropdown-dark-divider-bg);
$dropdown-dark-box-shadow: null;
$dropdown-dark-link-color: var(--#{$variable-prefix}dropdown-dark-link-color);
$dropdown-dark-link-hover-color: var(--#{$variable-prefix}dropdown-dark-link-hover-color);
$dropdown-dark-link-hover-bg: var(--#{$variable-prefix}dropdown-dark-link-hover-bg);
$dropdown-dark-link-active-color: var(--#{$variable-prefix}dropdown-dark-link-active-color);
$dropdown-dark-link-active-bg: var(--#{$variable-prefix}dropdown-dark-link-active-bg);
$dropdown-dark-link-disabled-color: var(--#{$variable-prefix}dropdown-dark-link-disabled-color);
$dropdown-dark-header-color: var(--#{$variable-prefix}dropdown-dark-header-color);

$pagination-padding-y: 0.375rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;

$pagination-color: var(--#{$variable-prefix}pagination-color);
$pagination-bg: var(--#{$variable-prefix}pagination-bg);
$pagination-border-width: $border-width;
$pagination-border-radius: $border-radius;
$pagination-margin-start: -$pagination-border-width;
$pagination-border-color: var(--#{$variable-prefix}pagination-border-color);

$pagination-focus-color: var(--#{$variable-prefix}pagination-focus-color);
$pagination-focus-bg: var(--#{$variable-prefix}pagination-focus-bg);
$pagination-focus-box-shadow: var(--#{$variable-prefix}pagination-focus-box-shadow);
$pagination-focus-outline: 0;

$pagination-hover-color: var(--#{$variable-prefix}pagination-hover-color);
$pagination-hover-bg: var(--#{$variable-prefix}pagination-hover-bg);
$pagination-hover-border-color: var(--#{$variable-prefix}pagination-hover-border-color);

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: var(--#{$variable-prefix}pagination-disabled-color);
$pagination-disabled-bg: var(--#{$variable-prefix}pagination-disabled-bg);
$pagination-disabled-border-color: var(--#{$variable-prefix}pagination-disabled-border-color);

$pagination-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

$pagination-border-radius-sm: $border-radius-sm;
$pagination-border-radius-lg: $border-radius-lg;

$placeholder-opacity-max: 0.5;
$placeholder-opacity-min: 0.2;

$card-spacer-y: $spacer;
$card-spacer-x: $spacer;
$card-title-spacer-y: $spacer * 0.5;
$card-border-width: $border-width;
$card-border-color: var(--#{$variable-prefix}card-border-color);
$card-border-radius: $border-radius;
$card-box-shadow: var(--#{$variable-prefix}card-box-shadow);
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-padding-y: $card-spacer-y * 0.5;
$card-cap-padding-x: $card-spacer-x;
$card-cap-bg: var(--#{$variable-prefix}card-cap-bg);
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: var(--#{$variable-prefix}card-bg);
$card-img-overlay-padding: $spacer - 0.25rem;
$card-group-margin: $grid-gutter-width * 0.5;

$accordion-padding-y: 1rem;
$accordion-padding-x: 1.25rem;
$accordion-color: var(--#{$variable-prefix}accordion-color);
$accordion-bg: transparent;
$accordion-border-width: $border-width;
$accordion-border-color: var(--#{$variable-prefix}accordion-border-color);
$accordion-border-radius: $border-radius;
$accordion-inner-border-radius: subtract($accordion-border-radius, $accordion-border-width);

$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;

$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: $accordion-color;
$accordion-button-bg: $accordion-bg;
$accordion-transition:
    $btn-transition,
    border-radius 0.15s ease;
$accordion-button-active-bg: var(--#{$variable-prefix}accordion-button-active-bg);
$accordion-button-active-color: var(--#{$variable-prefix}accordion-button-active-color);

$accordion-button-focus-border-color: var(--#{$variable-prefix}accordion-button-focus-border-color);
$accordion-button-focus-box-shadow: var(--#{$variable-prefix}accordion-button-focus-box-shadow);

$accordion-icon-width: 1.25rem;
$accordion-icon-color: $accordion-color;
$accordion-icon-active-color: $accordion-button-active-color;
$accordion-icon-transition: transform 0.2s ease-in-out;
$accordion-icon-transform: rotate(180deg);

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

$tooltip-font-size: 13px;
$tooltip-max-width: 200px;
$tooltip-color: var(--#{$variable-prefix}tooltip-color);
$tooltip-bg: var(--#{$variable-prefix}tooltip-bg);
$tooltip-border-radius: 0.2rem;
$tooltip-opacity: 0.9;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.8rem;
$tooltip-margin: 0;

$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
$tooltip-arrow-color: $tooltip-bg;

$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: null;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;

$popover-font-size: $font-size-sm;
$popover-bg: var(--#{$variable-prefix}popover-bg);
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: var(--#{$variable-prefix}popover-border-color);
$popover-border-radius: $border-radius-lg;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width);
$popover-box-shadow: $box-shadow;

$popover-header-bg: var(--#{$variable-prefix}popover-header-bg);
$popover-header-color: var(--#{$variable-prefix}popover-header-color);
$popover-header-padding-y: 0.7rem;
$popover-header-padding-x: 0.8rem;

$popover-body-color: var(--#{$variable-prefix}popover-body-color);
$popover-body-padding-y: 0.5rem;
$popover-body-padding-x: 0.75rem;

$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-color: var(--#{$variable-prefix}popover-arrow-color);

$popover-arrow-outer-color: var(--#{$variable-prefix}popover-arrow-outer-color);

$toast-max-width: 350px;
$toast-padding-x: 0.75rem;
$toast-padding-y: 0.5rem;
$toast-font-size: 0.875rem;
$toast-color: inherit;
$toast-background-color: var(--#{$variable-prefix}toast-background-color);
$toast-border-width: 1px;
$toast-border-color: var(--#{$variable-prefix}toast-border-color);
$toast-border-radius: $border-radius;
$toast-box-shadow: $box-shadow;
$toast-spacing: $container-padding-x;

$toast-header-color: inherit;
$toast-header-background-color: var(--#{$variable-prefix}toast-header-background-color);
$toast-header-border-color: var(--#{$variable-prefix}toast-header-border-color);

$badge-font-size: 0.75em;
$badge-font-weight: $font-weight-bold;
$badge-color: var(--#{$variable-prefix}badge-color);
$badge-padding-y: 0.25em;
$badge-padding-x: 0.4em;
$badge-border-radius: $border-radius;

$modal-inner-padding: $spacer - 0.5rem;

$modal-footer-margin-between: 0.5rem;

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: var(--#{$variable-prefix}modal-content-bg);
$modal-content-border-color: transparent;
$modal-content-border-width: $border-width;
$modal-content-border-radius: 0.2rem;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs: var(--#{$variable-prefix}modal-content-box-shadow-xs);
$modal-content-box-shadow-sm-up: var(--#{$variable-prefix}modal-content-box-shadow-sm-up);

$modal-backdrop-bg: var(--#{$variable-prefix}modal-backdrop-bg);
$modal-backdrop-opacity: 0.7;
$modal-header-border-color: var(--#{$variable-prefix}modal-header-border-color);
$modal-footer-border-color: var(--#{$variable-prefix}modal-footer-border-color);
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: $modal-inner-padding;
$modal-header-padding-x: $modal-inner-padding;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x;

$modal-sm: 300px;
$modal-md: 500px;
$modal-lg: 800px;
$modal-xl: 1140px;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;
$modal-scale-transform: scale(1.02);

$alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;
$alert-bg-scale: -80%;
$alert-border-scale: -70%;
$alert-color-scale: 40%;
$alert-dismissible-padding-r: $alert-padding-x * 3;

$progress-height: 1rem;
$progress-font-size: $font-size-base * 0.75;
$progress-bg: var(--#{$variable-prefix}progress-bg);
$progress-border-radius: $border-radius;
$progress-box-shadow: var(--#{$variable-prefix}progress-box-shadow);
$progress-bar-color: var(--#{$variable-prefix}progress-bar-color);
$progress-bar-bg: var(--#{$variable-prefix}progress-bar-bg);
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;

$list-group-color: var(--#{$variable-prefix}list-group-color);
$list-group-bg: var(--#{$variable-prefix}list-group-bg);
$list-group-border-color: var(--#{$variable-prefix}list-group-border-color);
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-item-padding-y: $spacer * 0.5;
$list-group-item-padding-x: $spacer * 0.8;
$list-group-item-bg-scale: -70%;
$list-group-item-color-scale: 40%;

$list-group-hover-bg: var(--#{$variable-prefix}list-group-hover-bg);
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: var(--#{$variable-prefix}list-group-disabled-color);
$list-group-disabled-bg: var(--#{$variable-prefix}list-group-disabled-bg);

$list-group-action-color: var(--#{$variable-prefix}list-group-action-color);
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: var(--#{$variable-prefix}list-group-action-active-color);
$list-group-action-active-bg: var(--#{$variable-prefix}list-group-action-active-bg);

$thumbnail-padding: 0.25rem;
$thumbnail-bg: var(--#{$variable-prefix}thumbnail-bg);
$thumbnail-border-width: $border-width;
$thumbnail-border-color: var(--#{$variable-prefix}thumbnail-border-color);
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: var(--#{$variable-prefix}thumbnail-box-shadow);

$figure-caption-font-size: $small-font-size;
$figure-caption-color: var(--#{$variable-prefix}figure-caption-color);

$breadcrumb-font-size: null;
$breadcrumb-padding-y: $spacer * 1;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding-x: 0.5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-bg: null;
$breadcrumb-divider-color: var(--#{$variable-prefix}breadcrumb-divider-color);
$breadcrumb-active-color: var(--#{$variable-prefix}breadcrumb-active-color);
$breadcrumb-divider: quote('\F0142');
$breadcrumb-divider-flipped: $breadcrumb-divider;
$breadcrumb-border-radius: null;

$carousel-control-color: var(--#{$variable-prefix}carousel-control-color);
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-control-hover-opacity: 0.9;
$carousel-control-transition: opacity 0.15s ease;

$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-opacity: 0.5;
$carousel-indicator-active-bg: var(--#{$variable-prefix}carousel-indicator-active-bg);
$carousel-indicator-active-opacity: 1;
$carousel-indicator-transition: opacity 0.6s ease;

$carousel-caption-width: 70%;
$carousel-caption-color: var(--#{$variable-prefix}carousel-caption-color);
$carousel-caption-padding-y: 1.25rem;
$carousel-caption-spacer: 1.25rem;

$carousel-control-icon-width: 2rem;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");

$carousel-transition-duration: 0.6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out;

$carousel-dark-indicator-active-bg: var(--#{$variable-prefix}carousel-dark-indicator-active-bg);
$carousel-dark-caption-color: var(--#{$variable-prefix}carousel-dark-caption-color);
$carousel-dark-control-icon-filter: invert(1) grayscale(100);

$spinner-width: 2rem;
$spinner-height: $spinner-width;
$spinner-vertical-align: -0.125em;
$spinner-border-width: 0.25em;
$spinner-animation-speed: 0.75s;

$spinner-width-sm: 1rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: 0.2em;

$btn-close-width: 1em;
$btn-close-height: $btn-close-width;
$btn-close-padding-x: 0.25em;
$btn-close-padding-y: $btn-close-padding-x;
$btn-close-color: var(--#{$variable-prefix}btn-close-color);
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#8b96a0'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
$btn-close-focus-shadow: none;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 0.8;
$btn-close-focus-opacity: 1;
$btn-close-disabled-opacity: 0.25;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);

$offcanvas-padding-y: $modal-inner-padding;
$offcanvas-padding-x: $modal-inner-padding;
$offcanvas-horizontal-width: 400px;
$offcanvas-vertical-height: 30vh;
$offcanvas-transition-duration: 0.3s;
$offcanvas-border-color: $modal-content-border-color;
$offcanvas-border-width: $modal-content-border-width;
$offcanvas-title-line-height: $modal-title-line-height;
$offcanvas-bg-color: $modal-content-bg;
$offcanvas-color: $modal-content-color;
$offcanvas-box-shadow: $modal-content-box-shadow-xs;
$offcanvas-backdrop-bg: $modal-backdrop-bg;
$offcanvas-backdrop-opacity: $modal-backdrop-opacity;

$code-font-size: 87.5%;
$code-color: var(--#{$variable-prefix}code-color);

$kbd-padding-y: 0.2rem;
$kbd-padding-x: 0.4rem;
$kbd-font-size: $code-font-size;
$kbd-color: var(--#{$variable-prefix}kbd-color);
$kbd-bg: var(--#{$variable-prefix}kbd-bg);

$pre-color: null;
