@mixin form-paddings {
    padding-left: 6px;
    padding-right: 6px;
}

@mixin remove-arrow-from-select {
    background-image: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: '';
    &::-ms-expand {
        display: none;
    }
}

@mixin center-spinner {
    display: flex;
    width: fit-content;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.projects-card {
    .table-custom-font-size {
        overflow: auto;
        th {
            &.Slides {
                width: 1rem;
            }
            &.Observaciones {
                width: 8rem;
                padding-left: 6px;
            }
        }
    }
}

.regiones-projects-column {
    .form-select {
        @include form-paddings;
        @include remove-arrow-from-select;
    }
    .loader {
        .spinner {
            @include center-spinner;
        }
    }
}

.pilares-projects-column {
    .form-select {
        @include form-paddings;
        @include remove-arrow-from-select;
    }
    .loader {
        .spinner {
            @include center-spinner;
        }
    }
}

.slides-projects-column {
    .loader {
        .spinner {
            @include center-spinner;
        }
    }
}

.diseñador-projects-column {
    .form-select {
        @include form-paddings;
        @include remove-arrow-from-select;
    }
    .loader {
        .spinner {
            @include center-spinner;
        }
    }
}

.observaciones {
    &-projects-column {
        width: 100%;
        height: 100%;
        .custom-observaciones-field {
            display: block;
            width: 100%;
            max-width: 126px;
            overflow-x: auto;
            overflow-y: hidden;
            padding: 0.44rem 6px;
            font-weight: 400;
            font-size: 0.8rem;
            line-height: 1.5;
            color: var(--ct-input-color);
            background-color: var(--ct-input-bg);
            background-clip: padding-box;
            border: 1px solid var(--ct-input-border-color);
            appearance: none;
            border-radius: 0.25rem;
            &.disabled {
                background-color: var(--ct-input-disabled-bg);
                opacity: 1;
            }
            &:hover {
                cursor: pointer;
            }

            /* width */
            &::-webkit-scrollbar {
                border-radius: 10px;
                height: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #5e6c75;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #272a2c;
            }
        }
        .loader {
            .spinner {
                @include center-spinner;
            }
        }
    }
    &-tooltip {
        &.show {
            opacity: 1;
        }
        .tooltip-inner {
            padding-right: 20px;
            position: relative;
            background-color: #dbdbdb;
        }
        .tooltip-arrow::before {
            border-left-color: #dbdbdb;
        }
    }
}

.factura-projects-column {
    .form-control {
        @include form-paddings;
    }
    .loader {
        .spinner {
            @include center-spinner;
        }
    }
}

.estado-projects-column {
    .form-select {
        @include form-paddings;
        @include remove-arrow-from-select;
    }
    .loader {
        .spinner {
            @include center-spinner;
        }
    }
}

.fecha-solicitud-projects-column {
    .loader {
        .spinner {
            @include center-spinner;
        }
    }
}

.fecha-entrega-projects-column {
    .loader {
        .spinner {
            @include center-spinner;
        }
    }
}
