.table-custom-font-size {
    font-size: 0.8rem;
    input,
    select,
    option {
        font-size: 0.8rem;
    }
    td {
        padding: 0.2rem;
    }
    th:first-child {
        padding: 0.3rem;
    }
    tr {
        font-size: 0.9rem;
        &:has(#ajuste) {
            font-size: 0.8rem;
            background-color: #f5ebe1;
        }
    }
    .react-datepicker-popper {
        .react-datepicker {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        }
    }
    thead {
        background-color: rgba(27, 40, 43, 0.14);
    }
}
