.optionSelect {
    color: #6c757d;
    background-color: #fff;
}

.frame-12 {
    width: 12px;
    height: 12px;
}

.cursor-text {
    &:hover {
        cursor: text;
    }
}

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

.custom-width {
    width: 70px;
}

.side-nav-title {
    background-color: $weikbluelight;
}

ul.side-nav > li.item-stripe {
    &:nth-child(even) {
        background-color: $table-striped-bg;
    }
}

.react-datepicker {
    &__header {
        &__dropdown {
            display: flex;
            padding: 0 0.7rem;

            & .react-datepicker__month-dropdown-container,
            .react-datepicker__year-dropdown-container {
                width: 50%;

                select {
                    width: 100%;
                    color: #7c8d96;
                    border-color: #7c8d96;
                }
            }
        }
    }
}

div.date_width_custom {
    div.react-datepicker-wrapper {
        width: 10rem;
    }

    div.range {
        div.react-datepicker-wrapper {
            width: 15rem;
        }
    }
}

div.date_width_custom_columns {
    &-deliveryDate {
        div.react-datepicker-wrapper {
            width: 6.6rem;
        }
    }

    div.react-datepicker-wrapper {
        width: 8rem;
    }
}

body {
    background-color: #f5f5f5;
    background: #f5f5f5;
}

body[data-layout='detached'] {
    .leftside-menu {
        a,
        p,
        span,
        ul,
        li {
            font-size: 0.8rem;
        }

        .side-nav {
            .menuitem-active {
                .side-nav-second-level {
                    :not(.active) {
                        a {
                            span:nth-child(1) {
                                color: #6c757d !important;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }

        .expand-menu-button {
            right: -0.8rem;

            &:hover {
                color: #1692ad;
            }
        }

        &.expanded_on {
            width: 6rem;
            max-width: 6rem;
            min-width: 6rem;

            .leftbar-user {
                padding: 1rem;
            }

            ul.side-nav {
                display: flex;
                flex-direction: column;
                gap: 6px;
                padding: 0 6px;

                li,
                li a,
                li a span,
                li p,
                li button,
                li button span {
                    font-size: 0.75rem;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                li.side-nav-title,
                li a,
                li button {
                    padding: 0.6rem 1rem !important;
                }

                li:first-child {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                li {
                    & > :nth-child(odd) {
                        background-color: #ececec;
                    }

                    i.mdi-account-supervisor {
                        display: none;
                    }

                    span.menu-arrow {
                        display: none;
                    }

                    &.side-nav-item.position-relative {
                        text-align: right;

                        button,
                        a {
                            width: 100%;
                        }

                        > span.badge {
                            right: 0;
                            top: 0;
                            left: inherit !important;
                            margin-top: 1rem !important;
                        }
                    }
                }

                & > li.side-nav-item.item-stripe:not(.position-relative) {
                    i {
                        margin: 0;
                        padding: 0;
                    }

                    span {
                        display: none;
                    }
                }

                li.side-nav-item.item-stripe.position-relative {
                    display: flex;
                    flex-direction: column;
                    background-color: #ececec;
                    background: #ececec;

                    button {
                        span:not(.menu-arrow) {
                            display: inline;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }

                    ul.side-nav-second-level.show {
                        display: flex;
                        flex-direction: column;

                        span.badge {
                            right: 0;
                            top: 0 !important;
                            left: inherit !important;
                            margin-top: 1rem !important;
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
            }

            .TeamMembers {
                .team-button {
                    display: flex !important;
                    flex-direction: column;
                    justify-content: center;

                    .member-image {
                        width: 100% !important;
                    }

                    .member-text {
                        width: 100% !important;

                        h5,
                        span {
                            display: block;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                }

                .card-header {
                    padding: 1rem 6px;
                }

                .card-body {
                    padding: 0;
                }
            }
        }

        .divider_diseñadores {
            margin: 0 auto;
            width: 88%;
            height: 2px;
            background-color: #6c757d !important;
        }

        .striped {
            &:nth-child(even) {
                background-color: #ececec;
            }
        }
    }
}

.react-datepicker__aria-live {
    display: none;
}

@include media-breakpoint-down(md) {
    label {
        font-size: 0.75rem;
    }

    div.row.AddClient,
    div.row.AddUser {
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 0.9rem;
        }

        p,
        li {
            font-size: 0.8rem;
        }
    }

    div.row.reports {
        h1.header-title {
            font-size: 1rem;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 0.8rem;
        }
    }
}

.form-control,
.form-select {
    border: 1px solid #dee2e6 !important;
    &::placeholder {
        color: #6c757d !important;
    }
}
