.weik-text {
    &-black {
        color: #000000 !important;
    }
    &-grey-500 {
        color: #12191d !important;
    }
    &-grey-400 {
        color: #313a46 !important;
    }
    &-grey-300 {
        color: #494949 !important;
    }
    &-grey-200 {
        color: #6c757d !important;
    }
    &-grey-100 {
        color: #6a6a6a !important;
    }
    &-white-500 {
        color: #eef2f7 !important;
    }
    &-white-400 {
        color: #e4e4e4 !important;
    }
    &-white-300 {
        color: #ccdde6 !important;
    }
    &-white-200 {
        color: #b1c1c9 !important;
    }
    &-white-100 {
        color: #98a6ad !important;
    }
}
.weik-bg {
    &-success {
        background-color: rgb(2 231 38 / 30%) !important;
    }
    &-info {
        background-color: rgb(11 140 227 / 30%) !important;
    }
    &-warning {
        background-color: rgb(255 185 0 / 35%) !important;
    }
    &-danger {
        background-color: rgb(255 31 76 / 28%) !important;
    }
}
