.TeamMembers {
    display: flex;
    flex-direction: row;

    .team-card {
        overflow: hidden;
        background-color: #ececec;
        border-radius: 10px;
        box-shadow: 0 0 10px #00000010;
        width: 8rem;
        height: 5.6rem;
        overflow: hidden;
    }

    .team-container {
        display: flex;
        flex-direction: row;
        overflow-x: visible;
        gap: 0.7rem;
    }

    .team-button {
        padding: 0.3rem;
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: rgba(187, 221, 235, 0.527);
        }
    }

    .member_image {
        &__online {
            border: 5px solid #0acf97;
        }

        &__offline {
            border: 5px solid #fd2c2c;
        }
    }

    .member-text {
        .user-name {
            font-size: 14px;
        }
    }
}
