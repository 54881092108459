.fc .fc-daygrid-day.fc-day-today {
    background-color: rgb(237 207 51 / 22%);
    color: #1d1d1d;
}

.react-datepicker__day,
.react-datepicker__month-text {
    &--disabled {
        cursor: default;
        color: white;
        background: #cccccc;
        border-radius: 0.3rem;

        &:hover {
            background: #cccccc;
        }
    }
}

.calendar-with-divider .react-datepicker__month-container {
    border-right: 1px solid #ccc;
}
